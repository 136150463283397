.modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  * {
      outline: none;
  }

  .modalBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
